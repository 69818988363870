<template>
  <paginated-table
    id="admin-payouts-table"
    ref="table"
    endpoint="payouts"
    :columns="columns"
    label="demandes de remboursement"
    sync-filters-with-url
    :show-generate-csv="false"
  >
    <template #cell(actions)="{ item }">
      <b-dropdown size="sm" variant="white-primary" no-caret toggle-class="mr-0">
        <template #button-content>
          <b-icon icon="three-dots-vertical" /><span class="sr-only">actions</span>
        </template>
        <b-dropdown-item
          v-b-modal="`link-to-kiwili-${item.id}`"
          :variant="item.kiwili_expense_id ? 'warning' : 'success'"
        >
          <b-icon icon="share-fill" />
          {{
            !item.kiwili_expense_id
              ? "Lier à une facture sur Kiwili"
              : "Modifier la facture liée sur Kiwili"
          }}
        </b-dropdown-item>
      </b-dropdown>
      <b-modal :id="`link-to-kiwili-${item.id}`" hide-footer hide-header>
        <link-payout-to-kiwili-form
          :payout="item"
          @saved="
            () => {
              $refs.table.refresh();
              $bvModal.hide(`link-to-kiwili-${item.id}`);
            }
          "
        />
      </b-modal>
    </template>
  </paginated-table>
</template>

<script>
import LinkPayoutToKiwiliForm from "@/components/Admin/LinkPayoutToKiwiliForm.vue";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { Column } from "@/components/shared/PaginatedTableColumns";

export default {
  name: "AdminPayouts",
  components: {
    LinkPayoutToKiwiliForm,
    PaginatedTable,
  },
  data() {
    const columns = [
      new Column("id", "ID", "id"),
      Column.withRelation(
        "user.full_name",
        "Membre",
        {
          relation: "users",
          label: "full_name",
          field: "id",
          params: { for: "admin" },
        },
        "user_id",
        {
          urlFct: (item) => `/admin/users/${item.user.id}`,
        }
      ),
      new Column("invoice_id", "Facture", "id", {
        urlFct: (item) => `/admin/invoices/${item.invoice_id}`,
      }),
      new Column("amount", "Total", "currency"),
      new Column("kiwili_expense_id", "Dépense Kiwili", "text", {
        urlFct: (item) =>
          process.env.VUE_APP_KIWILI_BASE_URL
            ? `${process.env.VUE_APP_KIWILI_BASE_URL}/app/depenses/edit/?id=${item.kiwili_expense_id}`
            : null,
      }),
      Column.withSelect(
        "status",
        "Statut",
        [
          { value: null, label: "Tous" },
          { value: "linked", label: "Sur Kiwili", variant: "success" },
          { value: "unlinked", label: "Pas lié à Kiwili", variant: "warning" },
        ],
        { sortable: false }
      ),
      new Column("created_at", "Date", "date"),
    ];

    return {
      columns,
    };
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss"></style>
