<template>
  <v-select
    class="forms-relation-input"
    :options="data"
    :placeholder="placeholder"
    :disabled="disabled"
    :filterable="false"
    :value="valueObjectFromSearch"
    :loading="loading"
    :clearable="showClearButton"
    append-to-body
    @search="setQ"
    @input="emitInput"
    @open="onOpen"
    @close="open = false"
  >
    <template #no-options>
      <small v-if="!q || q.length < minSearchLength" class="text-muted">
        Tapez quelque chose pour commencer à chercher...
      </small>
      <small v-else class="text-muted">Pas de résultat</small>
    </template>
    <template #option="option">
      <div class="option">
        <span>{{ option.label }}</span>
        <br />
        <small v-if="option.details" class="detail">{{ option.details }}</small>
      </div>
    </template>
  </v-select>
</template>

<script>
import { debounce } from "@/helpers/debounce";
import vSelect from "vue-select";

export default {
  name: "RelationInput",
  components: { vSelect },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    query: {
      type: Object,
      required: true,
    },
    resetAfterSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    objectValue: {
      type: Object,
      required: false,
      default: undefined,
    },
    value: {
      type: [String, Number, Object],
      required: false,
      default: undefined,
    },
    showClearButton: {
      type: Boolean,
      default: true,
    },
    minSearchLength: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      lastSelectedItem: null,
      searchDebounce: null,
      q: "",
      loading: false,
      open: false,
    };
  },
  computed: {
    context() {
      return this.$store.state[this.slug];
    },
    valueObjectFromSearch() {
      if (this.objectValue) {
        const value = this.dig(this.objectValue, this.query.value);
        const label = this.dig(this.objectValue, this.query.text);

        return {
          value,
          label,
        };
      }

      if (!this.value) {
        return null;
      }

      const correspondingSearchOptions = this.data.filter((f) => f.value == this.value);

      if (correspondingSearchOptions.length > 0) {
        return correspondingSearchOptions[0];
      }

      return this.lastSelectedItem;
    },
    data() {
      return this.context.search.map((d) => {
        return {
          ...d,
          value: this.dig(d, this.query.value),
          label: this.dig(d, this.query.text),
          details: this.query.details ? this.truncate(this.dig(d, this.query.details), 50) : "",
        };
      });
    },
    params() {
      const params = {
        ...this.query.params,
      };

      const fields = new Set((params.fields || "").split(","));
      fields.add(this.query.value);
      fields.add(this.query.text);
      if (this.query.details) {
        fields.add(this.query.details);
      }
      params.fields = [...fields].join(",");

      return params;
    },
    slug() {
      return this.query.slug;
    },
  },
  watch: {
    q() {
      if (this.open) {
        // On q change we don't want to fallback to value if it is empty.
        this.clearOrSearch(true, false);
      }
    },
    value(value) {
      if (value && !this.valueObjectFromSearch) {
        // If value is set from outside and we can't find it in the current search response
        // We do it silently to avoid showing a loading indicator in UI not interacted by user.
        this.clearOrSearch(true, true, true);
      }
    },
    valueObjectFromSearch(object) {
      this.$emit("search-object-updated", object);
    },
  },
  mounted() {
    this.debouncedSearch = debounce((fallbackToValue = true) => this.search(fallbackToValue));
    if (this.value) {
      // This will fallback to searching for the current value
      this.debouncedSearch(true);
    }
  },
  methods: {
    truncate(str, length) {
      if (!str) {
        return "";
      }
      return str.length > length ? str.slice(0, length - 1) + "..." : str;
    },
    dig(target, key) {
      const parts = key.split(".");

      return parts.reduce((acc, k) => {
        if (!acc) {
          return acc;
        }

        return acc[k];
      }, target);
    },
    emitInput(value) {
      this.$emit("input", value);

      if (this.resetAfterSelect) {
        this.reset();
      } else {
        this.lastSelectedItem = value;
      }
    },
    setQ(q) {
      this.q = q;
    },
    reset() {
      this.$emit("input", null);
      if (this.minSearchLength === 0) {
        this.search();
      }
    },
    onOpen() {
      // Do a clean search whenever we open, without falling back to searching for current value
      this.clearOrSearch(false, false);
      this.open = true;
    },
    async search(fallbackToValue = true) {
      try {
        const searchParams = {
          params: { ...this.params },
        };

        if (this.q) {
          searchParams.q = this.q;
        } else if (this.value && fallbackToValue) {
          // If user hasn't input anything (no q), we find the current value
          searchParams.params.id = this.value;
        }

        await this.$store.dispatch(`${this.slug}/search`, searchParams);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    clearOrSearch(debounce = true, fallbackToValue = true, silent = false) {
      if (this.minSearchLength > 0 && (!this.q || this.q.length < this.minSearchLength)) {
        // Cleanup search options, if we shouldn't be searching yet
        this.$store.commit(`${this.slug}/search`, []);
        return;
      }

      if (!silent) {
        this.loading = true;
      }
      if (debounce) {
        this.debouncedSearch(fallbackToValue);
      } else {
        this.search(fallbackToValue);
      }
    },
  },
};
</script>

<style lang="scss">
ul.vs__dropdown-menu {
  display: grid;
  box-shadow: $medium-shadow;
  min-width: 12rem;
  z-index: 1100; // above modals at (1050)
  .vs__dropdown-option {
    white-space: normal;
    line-height: 1.2;
    padding: 0.5rem;
    font-weight: 600;
    font-size: 0.9375rem;

    .detail {
      font-size: 0.8125rem;
      color: $content-neutral-secondary;
    }

    &.vs__dropdown-option--highlight .detail {
      color: $white;
    }
  }
}

.forms-relation-input {
  .vs__dropdown-toggle {
    background-color: $white;
  }

  &.vs--disabled {
    .vs__dropdown-toggle,
    .vs__search,
    .vs__open-indicator,
    .vs__clear {
      background-color: #e9ecef;
    }
  }
}
</style>
