<script>
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import ConditionalContext from "@/components/shared/ConditionalContext.vue";
import IconButton from "@/components/shared/IconButton.vue";
import { get, post } from "@/requests/server";

export default {
  name: "MailListForm",
  components: { ConditionalContext, FormsValidatedInput, IconButton },
  props: {
    communityId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      provider: null,
      apiKey: null,
      listId: null,
      tag: null,
      fetchingLists: false,
      lists: null,
      listsPage: 0,
      listsPerPage: 10,
      listsTotalCount: null,
      lastFetchParams: null,
      apiKeyHelpUrls: {
        brevo:
          "https://developers.brevo.com/docs/getting-started#using-your-api-key-to-authenticate",
        mailchimp: "https://mailchimp.com/help/about-api-keys/",
      },
      error: null,
      community: null,
    };
  },
  computed: {
    hasMoreLists() {
      return (
        this.listsTotalCount && this.listsTotalCount > (this.listsPage + 1) * this.listsPerPage
      );
    },
    listContactCount() {
      let lists = this.lists.filter((list) => list.id === this.listId);
      if (lists.length === 0) {
        return null;
      }
      return lists[0].contact_count ?? 0;
    },
    internalCommunityId() {
      if (this.community) {
        return this.community.id;
      }
      return this.communityId;
    },
  },
  watch: {
    provider(newVal, oldVal) {
      if (oldVal) {
        this.apiKey = null;
      }
    },
    apiKey() {
      this.lists = null;
      this.lastFetchParams = null;
      this.error = null;
    },
  },
  methods: {
    async prevPage() {
      if (this.listsPage > 0) {
        this.listsPage--;
      }
      await this.fetchLists();
    },
    async nextPage() {
      if (this.hasMoreLists) {
        this.listsPage++;
      }
      await this.fetchLists();
    },
    async fetchLists() {
      if (!this.apiKey || !this.provider) {
        return;
      }

      let params = {
        api_key: this.apiKey,
        provider: this.provider,
        page: this.listsPage,
        per_page: this.listsPerPage,
      };

      if (this.lastFetchParams === JSON.stringify(params)) {
        return;
      }

      this.fetchingLists = true;

      const { data } = await get(`mailinglists/lists`, {
        axiosRequestConfig: {
          params: params,
        },
        cleanupCallback: () => (this.fetchingLists = false),
        requestOptions: {
          cancelId: "mailinglists/lists",
        },
      });

      if (data.error) {
        this.error = data.error;
        return;
      }

      this.lists = data.lists;
      this.listsTotalCount = data.count;
      this.lastFetchParams = JSON.stringify(params);
    },
    onApiKeyPaste() {
      setTimeout(this.fetchLists, 50);
    },
    async createIntegration() {
      if (!this.listId || !this.internalCommunityId) {
        return;
      }

      const { data } = await post(
        "/mailinglists",
        {
          provider: this.provider,
          api_key: this.apiKey,
          list_id: this.listId,
          community_id: this.internalCommunityId,
          community_editable: !!this.communityId,
          tag: this.tag,
        },
        {
          notifications: {
            action: "création d'une intégration",
          },
        }
      );
      this.$emit("added", data);
    },
  },
};
</script>

<template>
  <validation-provider>
    <b-form>
      <h3>Nouvelle synchronisation</h3>

      <forms-validated-input
        v-if="!communityId"
        :object-value="community"
        :value="community ? community.id : null"
        type="relation"
        name="community_id"
        label="Communauté"
        :rules="{
          required: true,
        }"
        :query="{
          slug: 'communities',
          value: 'id',
          text: 'name',
          params: {
            for: 'admin',
          },
        }"
        @relation="community = $event"
      >
      </forms-validated-input>

      <b-form-group label="Fournisseur">
        <b-select v-model="provider" @blur="fetchLists">
          <b-select-option value="brevo">Brevo</b-select-option>
          <b-select-option value="mailchimp">Mailchimp</b-select-option>
        </b-select>
      </b-form-group>
      <b-form-group
        label="Clé d'api"
        :validated="!!lists"
        :state="error ? false : lists ? true : null"
      >
        <b-input
          v-model="apiKey"
          type="text"
          :state="error ? false : lists ? true : null"
          @blur="fetchLists"
          @paste="onApiKeyPaste"
        >
        </b-input>
        <template v-if="error" #invalid-feedback>
          Clé invalide&nbsp;: impossible de trouver les listes.
        </template>
        <template v-if="provider" #description>
          Obtenez la clé depuis votre compte {{ provider }}:
          <a :href="apiKeyHelpUrls[provider]" target="_blank">instructions</a>.
        </template>
      </b-form-group>
      <b-form-group label="Liste">
        <div v-if="fetchingLists" class="list-selection">
          <b-skeleton
            v-for="i in 4"
            :key="i"
            class="d-inline-block m-0"
            width="100%"
            type="button"
          ></b-skeleton>
        </div>
        <b-radio-group
          v-else-if="lists"
          v-model="listId"
          class="list-selection"
          buttons
          button-variant="outline-secondary"
        >
          <b-radio v-for="list in lists" :key="list.id" :value="list.id"
            >{{ list.name }} <span class="list-id">(#{{ list.id }})</span></b-radio
          >
        </b-radio-group>
        <div v-else class="info-section">Remplissez les autres champs pour voir vos listes.</div>
      </b-form-group>
      <div v-if="lists && listsTotalCount > listsPerPage" class="list-pagination">
        <icon-button
          :onclick="prevPage"
          :disabled="listsPage === 0 || fetchingLists"
          icon="arrow-left-circle"
          variant="ghost-secondary"
          size="sm"
          >Précédentes</icon-button
        >
        <icon-button
          :onclick="nextPage"
          :disabled="!hasMoreLists || fetchingLists"
          icon="arrow-right-circle"
          variant="ghost-secondary"
          size="sm"
          >Suivantes</icon-button
        >
      </div>
      <forms-validated-input
        v-if="provider === 'mailchimp'"
        v-model="tag"
        type="string"
        label="Tag"
        name="tag"
        description="Le tag qui sera ajouté à vos contacts s'ils sont approuvés dans la communauté."
        :rules="{
          required: true,
        }"
      ></forms-validated-input>

      <conditional-context
        v-if="provider"
        background="white"
        label="Informations"
        show
        class="mx-0"
      >
        <template v-if="provider === 'brevo'">
          <p>
            Les membres approuvés seront ajouté-es automatiquement comme contact à la liste de
            diffusion sélectionnée. Les membres qui quittent votre communauté ou qui sont
            suspendu-es seront uniquement retirés de cette liste, mais resteront comme contact de
            votre compte Brevo.
          </p>
          <p>
            Pour nettoyer périodiquement les membres qui ne sont plus approuvés de votre compte
            Brevo, vous pouvez filtrer les contacts qui ne sont membre d'aucune liste.
          </p>
          <p>
            Si vous comptez ajouter des contacts manuellement, assurez-vous de les assigner à une
            liste différente.
          </p>
          <p v-if="listId && listContactCount > 0" class="alert alert-warning">
            <strong>Attention&nbsp;:</strong>
            <span>
              Cette liste contient {{ listContactCount }} contact(s). Les contacts de la liste qui
              ne sont pas des membres approuvé-es de la communauté seront retirés.
            </span>
          </p>
        </template>
        <template v-if="provider === 'mailchimp'">
          <p>
            Les membres approuvés seront ajouté-es automatiquement comme contact à votre compte
            mailchimp avec le tag sélectionné. Les membres qui quittent votre communauté ou qui sont
            suspendu-es seront uniquement retirés de ce tag, mais resteront comme contact de votre
            compte Mailchimp.
          </p>
          <p>
            Pour nettoyer périodiquement les membres qui ne sont plus approuvés de votre compte
            Mailchimp, vous pouvez filtrer les contacts qui n'ont aucun tag assigné.
          </p>
          <p>
            Si vous comptez ajouter des contacts manuellement, assurez-vous de leur assigner un tag
            différent.
          </p>
        </template>
      </conditional-context>
      <icon-button
        :onclick="createIntegration"
        role="add"
        :disabled="!listId || !internalCommunityId"
        >Créer</icon-button
      >
    </b-form>
  </validation-provider>
</template>

<style scoped lang="scss">
.list-pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
}
.list-selection {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  gap: 0.5rem;

  .list-id {
    color: $content-neutral-secondary;
    transition: all 0.15s ease-in-out;
  }

  .btn {
    border-radius: 0.25rem !important; // undo styling form button-group, which we can't remove

    &.active,
    &:hover {
      .list-id {
        color: $white;
      }
    }
  }
}
.info-section {
  background: $eggshell;
  border: 1px solid $grey;
  color: $content-neutral-secondary;
  border-radius: 0.75rem;
  padding: 0.75rem;
  font-size: 0.875rem;
}
</style>
