<script>
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import IconButton from "@/components/shared/IconButton.vue";
import { put } from "@/requests/server";

export default {
  name: "LinkPayoutToKiwiliForm",
  components: { IconButton, FormsValidatedInput },
  props: {
    payout: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      kiwiliExpenseId: this.payout.kiwili_expense_id,
    };
  },
  methods: {
    async savePayout() {
      await put(
        `/payouts/${this.payout.id}`,
        {
          kiwili_expense_id: this.kiwiliExpenseId,
        },
        {
          notifications: { action: "mise à jour de la demande de paiement" },
        }
      );
      this.$emit("saved");
    },
  },
};
</script>

<template>
  <b-form>
    <forms-validated-input
      v-model="kiwiliExpenseId"
      type="text"
      name="kiwili-expense-id"
      label="ID de la dépense sur Kiwili"
      :rules="{
        required: true,
      }"
    ></forms-validated-input>
    <icon-button role="save" :onclick="savePayout">Enregistrer</icon-button>
  </b-form>
</template>

<style scoped lang="scss"></style>
