import { render, staticRenderFns } from "./LinkPayoutToKiwiliForm.vue?vue&type=template&id=5559bc56&scoped=true"
import script from "./LinkPayoutToKiwiliForm.vue?vue&type=script&lang=js"
export * from "./LinkPayoutToKiwiliForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5559bc56",
  null
  
)

export default component.exports